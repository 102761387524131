define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/modify-account-delete-function", ["exports", "@ember/object", "@ember/runloop", "discourse/lib/get-url", "discourse/lib/plugin-api", "discourse/lib/url", "discourse-i18n"], function (_exports, _object, _runloop, _getUrl, _pluginApi, _url, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function modifyAccountDeleteFunction(api) {
    api.modifyClass("controller:preferences/account", Superclass => class extends Superclass {
      delete() {
        const translation_string = this.model.any_posts ? "user.anonymize_account_confirm" : "user.delete_account_confirm";
        this.dialog.alert({
          message: (0, _discourseI18n.i18n)(translation_string),
          buttons: [{
            icon: "triangle-exclamation",
            label: (0, _discourseI18n.i18n)("user.delete_account"),
            class: "btn-danger",
            action: () => {
              return this.model.delete().then(() => {
                (0, _runloop.next)(() => {
                  this.dialog.alert({
                    message: (0, _discourseI18n.i18n)("user.deleted_yourself"),
                    didConfirm: () => _url.default.redirectAbsolute((0, _getUrl.default)("/")),
                    didCancel: () => _url.default.redirectAbsolute((0, _getUrl.default)("/"))
                  });
                });
              }, () => {
                (0, _runloop.next)(() => this.dialog.alert((0, _discourseI18n.i18n)("user.delete_yourself_not_allowed")));
                this.set("deleting", false);
              });
            }
          }, {
            label: (0, _discourseI18n.i18n)("composer.cancel")
          }]
        });
      }
      static #_ = (() => dt7948.n(this.prototype, "delete", [_object.action]))();
    });
  }
  var _default = _exports.default = {
    name: "modify-account-delete-function",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", modifyAccountDeleteFunction);
    }
  };
});