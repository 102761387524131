define("discourse/plugins/discourse-local-guides-connect/discourse/initializers/topic-navigation", ["exports", "discourse/lib/plugin-api", "discourse/models/nav-item", "discourse-i18n"], function (_exports, _pluginApi, _navItem, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function queryContains(positiveTerm, negativeTerm) {
    return (_, args) => {
      const queryParams = args.currentRouteQueryParams;
      return queryParams && queryParams["q"]?.includes(positiveTerm) && (!negativeTerm || !queryParams["q"]?.includes(negativeTerm));
    };
  }
  var _default = _exports.default = {
    name: "local-guides-connect-navigation",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const isMeetupCategory = category => category?.id === parseInt(siteSettings.meetup_category, 10);
      const meetupsCategory = {
        id: siteSettings.meetup_category,
        slug: "meet-ups" // TODO replace hardcoded slug
      };
      (0, _pluginApi.withPluginApi)("0.8.8", api => {
        const isMyMeetups = (category, args) => api.getCurrentUser() && (isMeetupCategory(category) && queryContains(`created-by:@${api.getCurrentUser().username}`)(category, args) || queryContains("in:messages")(category, args));
        const isAllMeetups = (category, args) => isMeetupCategory(category) && !isMyMeetups(category, args);
        const isMeetup = (category, args) => isMyMeetups(category, args) || isAllMeetups(category, args);
        const isPrivateCategory = category => category?.read_restricted;
        _navItem.default.reopenClass({
          buildList(category, args) {
            const items = this._super(category, args);
            // Omit Latest from Meetup category
            return items.filter(item => !isMeetup(category, args) || item.name !== "latest");
          }
        });

        // Private Category nav items
        api.addNavigationBarItem({
          name: "meetups",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.all"),
          title: (0, _discourseI18n.i18n)("topic_navigation.meetups.all"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:meetups`;
          },
          customFilter: isPrivateCategory,
          forceActive: queryContains("in:meetups")
        });
        api.addNavigationBarItem({
          name: "recaps",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.recaps"),
          title: (0, _discourseI18n.i18n)("topic_navigation.meetups.recaps"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:recaps`;
          },
          customFilter: isPrivateCategory,
          forceActive: queryContains("in:recaps")
        });
        api.addNavigationBarItem({
          name: "trending",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.trending"),
          title: (0, _discourseI18n.i18n)("topic_navigation.trending"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=order:hot`;
          },
          customFilter: (category, args) => !isMeetup(category, args),
          forceActive: queryContains("order:hot")
        });
        api.addNavigationBarItem({
          name: "bookmarked",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.bookmarked"),
          title: (0, _discourseI18n.i18n)("topic_navigation.bookmarked"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:bookmarked`;
          },
          customFilter: (category, args) => api.getCurrentUser() && !isMeetup(category, args),
          forceActive: queryContains("in:bookmarked")
        });
        if (siteSettings.local_guides_connect_following_filter_enabled) {
          api.addNavigationBarItem({
            name: "following",
            displayName: (0, _discourseI18n.i18n)("topic_navigation.following"),
            title: (0, _discourseI18n.i18n)("topic_navigation.following"),
            customHref: (category, args) => {
              const path = _navItem.default.pathFor("latest", args);
              return `${path}?q=in:following`;
            },
            customFilter: (category, args) => api.getCurrentUser() && !isMeetup(category, args),
            forceActive: queryContains("in:following")
          });
        }
        if (api.getCurrentUser()) {
          const createdBy = `created-by:@${api.getCurrentUser().username}`;
          api.addNavigationBarItem({
            name: "my_topics",
            displayName: (0, _discourseI18n.i18n)("topic_navigation.my_topics"),
            title: (0, _discourseI18n.i18n)("topic_navigation.my_topics"),
            customHref: (category, args) => {
              const path = _navItem.default.pathFor("latest", args);
              return `${path}?q=${createdBy}`;
            },
            customFilter: (category, args) => api.getCurrentUser() && !isMeetup(category, args),
            forceActive: queryContains(createdBy)
          });
        }

        // All Meetups

        api.addNavigationBarItem({
          name: "upcoming",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.upcoming"),
          title: (0, _discourseI18n.i18n)("topic_navigation.meetups.upcoming"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:upcoming`;
          },
          customFilter: isAllMeetups,
          forceActive: queryContains("in:upcoming")
        });
        api.addNavigationBarItem({
          name: "past",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.past"),
          title: (0, _discourseI18n.i18n)("topic_navigation.meetups.past"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:past`;
          },
          customFilter: isAllMeetups,
          forceActive: queryContains("in:past")
        });
        api.addNavigationBarItem({
          name: "attending",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.attending"),
          title: (0, _discourseI18n.i18n)("topic_navigation.meetups.attending"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:attending`;
          },
          customFilter: (category, args) => api.getCurrentUser() && isAllMeetups(category, args),
          forceActive: queryContains("in:attending")
        });
        api.addNavigationBarItem({
          name: "recaps",
          displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.recaps"),
          title: (0, _discourseI18n.i18n)("topic_navigation.meetups.recaps"),
          customHref: (category, args) => {
            const path = _navItem.default.pathFor("latest", args);
            return `${path}?q=in:recaps`;
          },
          customFilter: isAllMeetups,
          forceActive: queryContains("in:recaps", api.getCurrentUser() ? `created-by:@${api.getCurrentUser().username}` : null)
        });

        // My Meetups
        if (api.getCurrentUser()) {
          const createdBy = `created-by:@${api.getCurrentUser().username}`;
          api.addNavigationBarItem({
            name: "organized_by_me",
            displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.organized_by_me"),
            title: (0, _discourseI18n.i18n)("topic_navigation.meetups.organized_by_me"),
            customHref: (category, args) => {
              const path = _navItem.default.pathFor("latest", {
                ...args,
                category: meetupsCategory
              });
              return `${path}?q=${createdBy}`;
            },
            customFilter: isMyMeetups,
            forceActive: queryContains(createdBy, "in:recaps")
          });
          api.addNavigationBarItem({
            name: "my_recaps",
            displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.my_recaps"),
            title: (0, _discourseI18n.i18n)("topic_navigation.meetups.my_recaps"),
            customHref: (category, args) => {
              const path = _navItem.default.pathFor("latest", args);
              return `${path}?q=in:recaps%20${createdBy}`;
            },
            customFilter: isMyMeetups,
            forceActive: queryContains(`in:recaps ${createdBy}`)
          });
          api.addNavigationBarItem({
            name: "pending",
            displayName: (0, _discourseI18n.i18n)("topic_navigation.meetups.pending_approval"),
            title: (0, _discourseI18n.i18n)("topic_navigation.meetups.pending_approval"),
            customHref: (category, args) => {
              const path = _navItem.default.pathFor("latest", args);
              return `${path}?q=in:messages`;
            },
            customFilter: isMyMeetups,
            forceActive: queryContains("in:messages")
          });
        }
      });
    }
  };
});